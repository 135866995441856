import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import $ from "jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import { CartProvider } from "react-use-cart";
// import { hydrate, render } from "react-dom";

const queryClient = new QueryClient();

if (false) {
  window.apiBaseUrl = "http://localhost/cosmosiq/website-apis/v3/website/";
  // window.apiBaseUrl = "http://localhost/COSMOSIQ_WEBSITE_REACT_JS_APIS/v3/website/";
} else {
  // window.apiBaseUrl = "https://www.cosmosiq.ai/m-apis/v3/website/";
  window.apiBaseUrl = "https://www.cosmosiq.ai/apis/v3/website/";
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <CartProvider>
      <App />
    </CartProvider>
    <ToastContainer />
  </QueryClientProvider>
  // </React.StrictMode>
);
